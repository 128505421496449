.slider-container {
  --thumb-width: 8px;
  --thumb-height: 8px;
}

.range::-webkit-slider-thumb {
  width: var(--thumb-width);
  height: var(--thumb-height);

  background: #350f2d;
  border: 1px solid #000000;
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
}

.thumb {
  width: var(--thumb-width);
  height: var(--thumb-height);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.753);
  z-index: 3;
  /* background: rgb(255, 255, 255);
   */
  position: absolute;
  border-radius: 50%;
  top: 50%;
  transform: translate(0%, -50%);
  pointer-events: none; /* Remove pointer events on thumb so user can click on the actual thumb beaneath it!  */
  user-select: none; /*  Prevent Accidentally highlighting the number while sliding the cursor  */
  background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);

}
