.auth-layout {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  /* font-family: Axiforma !important; */
  font-family: 'Graphik', sans-serif !important;
}

.auth-bg-img,
.bg-img {
  height: 100%;
  width: 100;
}

.bg-img {
  object-fit: cover;
}

.auth-layout:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(179.74deg, rgba(18, 18, 18, 0.59) -28.03%, rgba(16, 16, 16, 0.98) 71.57%);
}

.register-form__container {
  background: linear-gradient(179.74deg, rgba(18, 18, 18, 0.59) -28.03%, rgba(16, 16, 16, 0.98) 71.57%), url('../img/authbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-content-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}



.auth-form {
  /* background: rgba(18, 18, 18, 0.82);
    backdrop-filter: blur(6px);
    position: relative;
   
    border-radius: 28px;
  
    padding: 24px 41px;
    border-right: 6px solid;
    border-bottom: 6px solid;
    border-left: 6px solid;
    border-top: 6px solid;
    width: 30.69%;
    border-image: linear-gradient(30deg, rgba(18, 18, 18, 0.82) 2%, #8C8C8C, rgba(0, 0, 0, 0)) 1;
    clip-path: inset(5px round 11px); */
  background: rgba(18, 18, 18, 0.82);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  position: relative;
  border-radius: 28px;
  /* width: 422px; */
  padding: 24px 41px;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-top: 1px solid;
  width: 30.69%;
  /* border-image: linear-gradient(30deg, rgba(18, 18, 18, 0.82) 2%, #8C8C8C, rgba(0, 0, 0, 0)) 1; */
  /* clip-path: inset(5px round 11px);*/
}




/* .auth-form::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    height: 20px;
    width: 20px;
    border-bottom: 1px solid #8C8C8C;
    border-bottom-right-radius: 28px;
  }
  
  .auth-form::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 20px;
    width: 20px;
    border-top: 1px solid #8C8C8C;
    border-top-left-radius: 28px;
  } */




.form-title {
  font-family: 'Axiforma';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.03em;

  color: #FFFFFF;
}

.logo-wrapper {
  padding-bottom: 16.62px;
}

.form-wrapper {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-label {
  font-family: 'Axiforma';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  /* identical to box height */

  /* text-align: center; */

  color: #FFFFFF;
}

.phone-number-wrapper {
  border: 1px solid #8C8C8C !important;
  border-radius: 30px !important;
  padding: 1px;
  background: transparent !important;
  overflow: hidden;

}

.phone-number-wrapper select:focus,
.phone-number-wrapper input:focus {
  border: none !important;
}

select {
  position: relative;
  background-image: url('../img/authSelect.svg');
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
  background-color: transparent;
  appearance: none;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  cursor: pointer;
  border: none;
  /* padding-left: 13px !important; */

  color: white;
}

.phone-number-wrapper .select-input-wrapper {

  border-right: 1px solid #8C8C8C !important;

}

.phone-number-wrapper input {
  position: relative;

}

.phone-number-wrapper .auth-input {
  border: none !important;
  border-radius: 0px !important;
  position: relative;
}

.phone-number-wrapper span {
  position: relative;
}

.phone-number-wrapper span::before {
  content: '';
  position: absolute;
  background-color: #8C8C8C;
  left: 5px;
  top: 12px;
  width: 1px;
  height: 29px;
  z-index: 9;
}


.auth-input,
.otp-input {
  /* background: #262626 !important; */
  background: transparent !important;
  border: 1px solid #8C8C8C !important;
  /* backdrop-filter: blur(4px) !important; */
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 30px !important;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF !important;
  transition: border ease-in-out 100ms !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 20px !important;


}

.otp-input {
  border-radius: 5px !important;

}

.auth-input:-webkit-autofill,
.auth-input:-webkit-autofill:hover,
.auth-input:-webkit-autofill:focus,
.auth-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #262626 inset !important;
  -webkit-text-fill-color: #fff !important;

}

textarea {
  resize: none !important;
}

textarea:focus,
input:focus,
.auth-input:focus,
.password-input-wrapper:focus,
.otp-input:focus,
select:focus,
.phone-number-wrapper:focus-within {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #C084FF !important;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; */
  /* backdrop-filter: blur(4px) !important; */
  border-radius: 30px !important;
  background: transparent !important;

}

.password-input-wrapper .auth-input,
.password-input-wrapper .auth-input:focus {
  border: none !important;
  background: transparent !important;
  border: none !important;
  outline: none !important;
}

.visibility-icon {
  text-align: center;
  color: #FFFFFF;
  /* background: #373737; */
  background: transparent;
  border-radius: 5px;
  padding: 5px 15px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  right: 10px;
  top: 25%;
}

.otp-input {
  width: 50px;
  height: 50px;
  left: 833px;
  top: 274px;

  background: #262626;
  border: 1px solid #8C8C8C;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 5px;
}

.otp-verify-wrapper {
  margin-top: 68px;
  margin-bottom: 60px;

}

.auth-link,
.auth-link:hover {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.03em;

  color: #8642CC;
  text-decoration: none;
}

.forgot-password-link,
.sign-in-btn {
  margin-bottom: 25px;
}

.forgot-password-link .auth-link {
  font-size: 12px;
  color: #888888 !important;


}

.sign-text {
  margin-bottom: 20px;
}

.auth-btn {
  background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%) !important;
  border-radius: 100px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  text-align: center !important;

  color: #FFFFFF !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;

  transition: background 0.5s ease-in !important;
}

.auth-btn:hover {
  background: linear-gradient(86.63deg, #9B42C0 11.49%, #5F42E2 131%) !important;

}

.auth-btn:disabled {
  opacity: 0.6 !important;
  border: none;

}

.auth-text {
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  color: #FFFFFF;
}

.create-text {
  margin-bottom: 29px;
}

.otp-text,
.verify-email {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  color: #CECECE;
}

.verify-email {
  color: #D5ACFF;
}

.resend-otp-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #C4C4C4;
}

.resend-countdown {
  color: #FFF;
}

.back-icon {
  width: 33px;
  height: 33px;
  top: 20px;
  left: 20px;
  background: #262626;
  backdrop-filter: blur(2.69388px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 9px;
}

.congrats-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  /* identical to box height */
  text-align: center;
  letter-spacing: -0.03em;
  color: #FFFFFF;
}

.congrats-sub-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #C4C4C4;
  width: 75%;
  margin: 0 auto 48px auto;
}

.animation-wrapper {
  margin-top: 50.2px;
  margin-bottom: 20px
}

.password-reset-email {
  margin-top: 58px;
  margin-bottom: 58px
}

@media (max-width:768px) {
  .auth-form {
    width: 85%;
    background: unset !important;
    -webkit-backdrop-filter: unset !important;
    backdrop-filter: unset !important;
    border-radius: 0px;
    padding: 24px 0px;
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
    border-top: none !important;
  }

  .auth-form.mobile-form {
    height: calc(81vh - 23px);
    margin-top: 23px;
  }

  .register-form__container {
    background: linear-gradient(to bottom, #0d0d0d 43.43%, rgba(0, 0, 0, 0.7) 134.97%), url('../img/authbg3.png') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .auth-content-wrapper {
    background: #0d0d0d;
    position: relative !important;
  }

  .otp-input {
    width: 42px;
    height: 42px;
  }

  .logo-wrapper {

    border-bottom: 1px solid rgba(142, 142, 142, 0.20);
  }

  .auth-layout:before {
    background: unset !important;
  }
  .auth-bg-img{
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .auth-form {
    width: 65%;
    padding: 24px 35px;
  }
}


@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .auth-form {
    width: 50%;
    padding: 24px 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .auth-form {
    width: 40%;
    padding: 24px 40px;
  }
}

.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 30px;
}

.strike>span {
  position: relative;
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #FDFDFD;
}

.strike>span:before,
.strike>span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 150px;
  height: 1px;
  background: #8E8E8E;
  opacity: 0.2;
}

.strike>span:before {
  right: 100%;
  margin-right: 15px;
}

.strike>span:after {
  left: 100%;
  margin-left: 15px;
}

.social-login-wrapper {
  border: 1px solid #8E8E8E !important;
  border-radius: 100px !important;
  padding: 12px !important;
  margin-bottom: 16px;
  cursor: pointer;

}

.social-login_logo {}

.social-login_name {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #FDFDFD;
}

.cstm-react__select__control,
.cstm-react__select__control--is-focused {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  outline: none !important;

}

.cstm-react__select__wrapper {
  background-color: transparent !important;
  border: 1px solid #8C8C8C !important;
  /* backdrop-filter: blur(4px); */
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 30px !important;
}

.cstm-react__select__wrapper.error_detected {
  border: 1px solid #dc3545 !important;

}

.cstm-react__select__wrapper:focus-within {
  border: 1px solid #C084FF !important;
}


.cstm-react__select__input-container input:focus {
  border: none !important;
}

.cstm-react__select__indicator.cstm-react__select__dropdown-indicator svg {
  display: none;
}

.cstm-react__select__indicator.cstm-react__select__dropdown-indicator {
  background-image: url('../img/authSelect.svg');
  background-repeat: no-repeat;
  margin-top: 15px;
}

.cstm-react__select__indicator-separator {
  display: none !important;
}

.cstm-react__select__input-container input {
  padding: 0px !important;
}

.cstm-react__select__input-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;


}

.cstm-react__select__menu {
  z-index: 999;
  background: rgba(217, 217, 217, 0.19) !important;
  backdrop-filter: blur(30px) !important;
  color: #fff;
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;

}

.cstm-react__select__single-value {
  color: white !important;
}

.authorized-wrapper__initials-container {
  width: 40px;
  height: 40px;
  left: 31px;
  top: 112px;
  background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;

  color: #FFFFFF;
  border-radius: 50%;
  margin-right: 16px;
}