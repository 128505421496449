/* .StripeElement {
    margin: 15px auto;
    padding: 10px 12px;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
  
  .product {
    width: 100%;
    max-width: 450px;
    margin: auto;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .btn-pay {
    display: block;
    width: 100%;
    border: none;
    background: linear-gradient(
      135deg,
      rgb(49, 0, 62) 0%,
      rgb(195, 40, 110) 100%
    );
    color: #fff;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .product-info {
    padding: 0 16px;
  }
  
  h3.product-title {
    font-size: 28px;
    margin-bottom: 15px;
  }
  
  h4.product-price {
    font-size: 24px;
    margin: 0;
    margin-bottom: 30px;
    color: #777;
    font-weight: 500;
  }
  form {
    width: 480px;
    margin: 20px 0;
  }
  
  .group {
    background: white;
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  label {
    position: relative;
    color: #8898AA;
    font-weight: 300;
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
  }
  
  .group label:not(:last-child) {
    border-bottom: 1px solid #F0F5FA;
  }
  
  label > span {
    width: 120px;
    text-align: right;
    margin-right: 30px;
  }
  
  label > span.brand {
    width: 30px;
  }
  
  .field {
    background: transparent;
    font-weight: 300;
    border: 0;
    color: #31325F;
    outline: none;
    flex: 1;
    padding-right: 10px;
    padding-left: 10px;
    cursor: text;
  }
  
  .field::-webkit-input-placeholder {
    color: #CFD7E0;
  }
  
  .field::-moz-placeholder {
    color: #CFD7E0;
  }
  
  button {
    float: left;
    display: block;
    background: #666EE8;
    color: white;
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 0;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    height: 40px;
    line-height: 38px;
    outline: none;
  }
  
  button:focus {
    background: #555ABF;
  }
  
  button:active {
    background: #43458B;
  }
  
  .outcome {
    float: left;
    width: 100%;
    padding-top: 8px;
    min-height: 24px;
    text-align: center;
  }
  
  .success,
  .error {
    display: none;
    font-size: 13px;
  }
  
  .success.visible,
  .error.visible {
    display: inline;
  }
  
  .error {
    color: #E4584C;
  }
  
  .success {
    color: #666EE8;
  }
  
  .success .token {
    font-weight: 500;
    font-size: 13px;
  }
   */
.card-input__wrapper:focus-within {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #C084FF !important;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; */
  /* backdrop-filter: blur(4px) !important; */
  border-radius: 8px !important;
  background: transparent !important;

}

.card-input__wrapper {
  background: transparent !important;
  border: 1px solid #8C8C8C !important;
  backdrop-filter: blur(4px) !important;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px !important;
  font-size: 14px;
  line-height: 22px;
  color: #000 !important;
  transition: border ease-in-out 100ms !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 20px !important;
}

.get-app-btn.btn-pay {
  border-radius: 10px !important;
}