.control-panel {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  margin-top: -15px;

}

.timer {
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: center;

  color: #FFFFFF;
}

.play-ctrl-btn {
  margin: 0 20.23px;
}